import React from "react"

import { motion } from "framer-motion"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <motion.div
      animate={{
        scale: [1, 2, 2, 2, 2, 1, 1],
        rotate: [0, 360, 360, 360, 360, 360],
      }}
      transition={{
        duration: 1,
        repeatDelay: 1,
        loop: Infinity,
      }}
      className="w-1/2 mx-auto text-6xl text-center font-semibold"
    >
      404
    </motion.div>
    <div className="mt-16 font-semibold text-2xl text-center">
      Page not found
    </div>
  </Layout>
)

export default NotFoundPage
